import React from 'react';
import Home from '../../components/organisms/Home';
import Seo from '../../components/seo';
import { FullPage, Slide } from 'react-full-page';
import About from '../../components/organisms/About';
import FactorX from '../../components/organisms/FactorX';
import WhatMakeUsStandOut from '../../components/organisms/WhatMakeUsStandOut';
import WhyUs from '../../components/organisms/WhyUs';
import LayoutES from '../../templates/LayoutES';
import LetsStart from '../../components/organisms/LetsStart';
import { graphql } from 'gatsby';

// const About = React.lazy(() => import('../components/organisms/About'));
// const FactorX = React.lazy(() => import('../components/organisms/FactorX'));
// const WhatMakeUsStandOut = React.lazy(() => import('../components/organisms/WhatMakeUsStandOut'));
// const WhyUs = React.lazy(() => import('../components/organisms/WhyUs'));




interface ComponentProps {
  data: any
}

const IndexPage: React.FC<ComponentProps> = ({ data }) => {
  // const dispatch = useAppDispatch();
  // dispatch(setActiveLanguage('es'))

  const homeData = data?.datoCmsHomeModule || '';
  const aboutData = data?.datoCmsAboutModule || '';
  const whyUsData = data?.datoCmsWhyUsModule || '';
  const whatMakeUsStandOutData = data?.datoCmsWhatMakeUsStandOutModule || '';
  const factorXData = data?.datoCmsFactorXModule || '';
  const letsStartData = data?.datoCmsLetsStartModule || '';

  return (
    <LayoutES>
      {/* <FullPage controls>
      <Slide>      <div id="home">
        <Home data={homeData} />
      </div></Slide>
      <Slide> <About data={aboutData} /></Slide>
      <Slide><WhyUs data={whyUsData} /></Slide>
      <Slide> <WhatMakeUsStandOut data={whatMakeUsStandOutData} /></Slide>
      <Slide>      <FactorX data={factorXData} /></Slide>
      <Slide> <LetsStart data={letsStartData} /></Slide>

      </FullPage> */}
      <Seo title="Vivid Design Art" description="Una Dimensión Diferente De Diseño" />


      <div id="home">
        <Home data={homeData} aboutData={aboutData} />
      </div>

      <About data={aboutData} />
      <WhyUs data={whyUsData} />
      <WhatMakeUsStandOut data={whatMakeUsStandOutData} />
      <FactorX data={factorXData} />
      <LetsStart data={letsStartData} homeData={homeData} />

    </LayoutES>
  );
};

export default IndexPage;

export const query = graphql`


  query allDataEs {
    datoCmsAboutModule(locale: {eq: "es"}) {
    textBottomHover
    textBottom
    photoText
    description
    photo {
      gatsbyImageData
      url
    }
  }
  datoCmsWhyUsModule(locale: {eq: "es"}) {
    textBottomHover
    textBottom
    description
    photoHover {
      gatsbyImageData
      url
    }
    photo {
      gatsbyImageData
      url
    }
    gallery {
      year
      description
      country
      photo {
        gatsbyImageData
      }
    }
  }
  datoCmsWhatMakeUsStandOutModule(locale: {eq: "es"}) {
    textBottomHover
    textBottom
    photoText
    description
    photo {
      gatsbyImageData
      url
    }
  }
  datoCmsFactorXModule(locale: {eq: "es"}) {
    textBottomHover
    textBottom
    description
    gallery {
      description
      country
      year
      photo {
        gatsbyImageData
      }
    }
    photoHover {
      gatsbyImageData
      url
    }
    photo {
      gatsbyImageData
      url
    }
  }
  datoCmsLetsStartModule(locale: {eq: "es"}) {
    website
    websiteInfo
    textBottomHover
    textBottom
    stepTwo
    stepThree
    stepOne
    stepFour
    stepFive
    socialInfo
    phone
    phoneInfo
    mailInfo
    mail
    linkedinUrl
    instagramUrl
    facebookUrl
    header
    photoText
    photoTextLineThree
    photoTextLineOne
    photoTextLineTwo {
      text
    }
    photo{
      url
    }
  }
  datoCmsHomeModule(locale: {eq: "es"}) {
    titleForScroll
  }
}

`
